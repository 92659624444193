import './datepicker';

// Element variables
const oddAdress = document.getElementById("odd-adress");
const lightSwitches = document.getElementsByClassName("lightswitch");
const searchInputs = document.getElementsByClassName("form__search-input");
const accordionButtons = document.getElementsByClassName("accordion__button");
const numberInputs = document.getElementsByClassName("specifications__number-input");
const packageExpandDetailButtons = document.getElementsByClassName("package__expand");

// Click events
for (let i = 0; i < accordionButtons.length; i++) accordionButtons[i].addEventListener("click", accordionClick);
for (let i = 0; i < lightSwitches.length; i++) lightSwitches[i].addEventListener("click", switchToggle);
for (let i = 0; i < packageExpandDetailButtons.length; i++) packageExpandDetailButtons[i].addEventListener("click", detailsToggle);

// Input events
if (oddAdress) oddAdress.addEventListener("input", toggleBillingData);
for (let i = 0; i < searchInputs.length; i++) searchInputs[i].addEventListener("keyup", searchInputKeyUp);
for (let i = 0; i < numberInputs.length; i++) numberInputs[i].addEventListener("input", numberEventPreventNegativeNumbers);

// Functions
function searchInputKeyUp (event) {
    const searchValue = event.target.value.toUpperCase();
    const searchBoxes = event.target.parentElement.getElementsByClassName("form__search-box");
    let searchOptions = searchBoxes[0] ? searchBoxes[0].children : [];

    let amountDisplayed = 0;

    for (let i = 0; i < searchOptions.length; i++) {
        if (searchOptions[i].tagName === 'DIV') { searchOptions[i].remove(); break; }
        if (searchOptions[i].innerText.toUpperCase().indexOf(searchValue) > -1) { searchOptions[i].style.display = ""; amountDisplayed++; }
        else searchOptions[i].style.display = "none";
    }

    if (amountDisplayed === 0) {
        var div = document.createElement("div");
        div.innerHTML = "Geen zoekresultaten gevonden. :("
        event.target.parentElement.getElementsByClassName("form__search-box")[0].appendChild(div);
    }
}

function accordionClick (event) {
    event.preventDefault();

    const accordion = event.target.parentElement.parentElement;
    accordion.classList.contains("accordion--active") ? accordion.classList.remove("accordion--active") : accordion.classList.add("accordion--active");
}

function switchToggle (event) {
    event.preventDefault();

    const lightSwitch = event.target;
    lightSwitch.classList.contains("lightswitch--active") ? lightSwitch.classList.remove("lightswitch--active") : lightSwitch.classList.add("lightswitch--active");
}

function detailsToggle (event) {
    event.preventDefault();

    const details = event.target.parentElement.parentElement.getElementsByClassName("package__details")[0];
    event.target.classList.contains("package__expand--active") ? event.target.classList.remove("package__expand--active") : event.target.classList.add("package__expand--active");
    details.classList.contains("package__details--active") ? details.classList.remove("package__details--active") : details.classList.add("package__details--active");
}

function toggleBillingData (event) {
    const billingDataCategory = document.getElementById("billing-data-category");
    event.target.checked ? billingDataCategory.style.display = "block" : billingDataCategory.style.display = "none";
}

function numberEventPreventNegativeNumbers (event) { if (event.target.value < 0) event.target.value = 0; }
